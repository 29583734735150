import React, { useState, useEffect, useCallback } from "react";

import {Table,Container,Row,Col,Button} from "react-bootstrap"
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'


const PlayerPop = (props) => {

  const show = props.show;
  const handleClose = props.handleClose;
  const playerData = props.playerData;
  const player_img = "https://platform-static-files.s3.amazonaws.com/premierleague/photos/players/110x140/p"+playerData.plcode+".png"

var pop_up = "";

function PopUp() {
  let pop_up="";
  const mp_r = () => {
    if(playerData.mp_r>59){
      return (<> Mins Played: +2 <br/></>)}
    else if(playerData.mp_r>0){
      return (<> Mins Played: +1 <br/></>)}
    else{return null}
  }

  const gs = () => { if(playerData.gs>0){return ( <> {"Goals Scored: +" +playerData.gs}<br/></>)} else{return null}}
  const assists = () => { if(playerData.assists>0){return ( <> {"Assists: +" +playerData.assists}<br/></>)} else{return null}}
  const penmiss = () => { if(playerData.penmiss<0){return ( <> {"Pen Miss: " +playerData.penmiss}<br/></>)} else{return null}}
  const saves = () => { if(playerData.saves>0){return ( <> {"Saves: +" +playerData.saves}<br/></>)} else{return null}}
  const pensave = () => { if(playerData.pensave>0){return ( <> {"Pen Save: +" +playerData.pensave}<br/></>)} else{return null}}
  const cs= () => { if(playerData.cs>0){return ( <> {"Clean Sheet: +" +playerData.cs}<br/></>)} else{return null}}
  const gc = () => { if(playerData.gc<0){return ( <> {"Goals Conceded: " +playerData.gc}<br/></>)} else{return null}}
  const yc = () => { if(playerData.yc<0){return ( <> {"Yellow Card: " +playerData.yc}<br/></>)} else{return null}}
  const rc = () => { if(playerData.rc<0){return ( <> {"Red Card: " +playerData.rc}<br/></>)} else{return null}}
  const og = () => { if(playerData.og<0){return ( <> {"Own Goal: " +playerData.og}<br/></>)} else{return null}}
  const bonus = () => { if(playerData.bonus>0){return ( <> {"Bonus Pts: +" +playerData.bonus}<br/></>)} else{return null}}


  // if(playerData.mp_r>59){pop_up = "Mins Played: +2<br>";} else if(playerData.mp_r>0){pop_up = "Mins Played: +1<br>";}
  // if(playerData.gs>0){pop_up += "Goals Scored: +"+playerData.gs + " <br>";}
  // if(playerData.assists>0){pop_up += "Assists: +"+playerData.assists+" <br>";}
  // if(playerData.penmiss<0){pop_up += "Pen Miss: "+playerData.penmiss+" <br>";}
  // if(playerData.saves>0){pop_up += "Saves: +"+playerData.saves+" <br>";}
  // if(playerData.pensave>0){pop_up += "Pen Save: +"+playerData.pensave+" <br>";}
  // if(playerData.cs>0){pop_up += "Clean Sheet: +"+playerData.cs+" <br>";}
  // if(playerData.gc<0){pop_up += "Goals Conceded: "+playerData.gc+" <br>";}
  // if(playerData.yc<0){pop_up += "Yellow Card: "+playerData.yc+" <br>";}
  // if(playerData.rc<0){pop_up += "Red Card: "+playerData.rc+" <br>";}
  // if(playerData.og<0){pop_up += "Own Goal: "+playerData.og+" <br>";}
  // if(playerData.bonus>0){pop_up += "Bonus Pts: +"+playerData.bonus+" <br>";}
  const tot = "Week Points: "+playerData.wk_pts;

  return (<>
            <div>
              {mp_r()}
              {gs()}
              {assists()}
              {penmiss()}
              {saves()}
              {pensave()}
              {cs()}
              {gc()}
              {yc()}
              {rc()}
              {og()}
              {bonus()}
              <br/>
              {tot}
            </div>
          </>
            )
}

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{playerData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <PopUp/>
            </Col>
            <Col>
            <img
              src={player_img}
              style ={{maxWidth:"100%", height:"auto"}}
            />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PlayerPop
