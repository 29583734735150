import React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
// import Leagues from '../components/Leagues'
// import Lms from '../components/Lms'
import HeadTags from '../components/Headtags22'
import HeadtoHead from '../components/HeadtoHead2'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

// import Amplify from 'aws-amplify';
// import config from '../aws-exports';
//
// Amplify.configure(config);

const H2hPage = ({data}) => {
   const post = data.markdownRemark

    return (
      <>
        <HeadTags />
        <div style ={{position:"relative", minHeight:"100vh"}}>
        <Row>
            <SnackAd2 ad_type = "leader" ad_num = {1}/>
        </Row>
          <Header />
          <HeadtoHead lgCode = {post.frontmatter.code} lgName = {post.frontmatter.title}/>
          <Container fluid>
            <Row>
              <SnackAd ad_type = "leader" ad_num = {4}/>
            </Row>
          </Container>
          <Footer />
        </div>
      </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug_lg: { eq: $slug} }) {
      html
      frontmatter {
        title
        keywords
        code
        type
      }
    }
  }
`

export default H2hPage
